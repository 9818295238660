import graph from './graphapi';

export async function createEvent(event) {
  const newEvent = await graph.post('me/events', JSON.stringify(event));
  return newEvent;
}

async function getNextContacts(nextLink) {
  let contacts = [];
  const contactResponse = await graph.get(nextLink);
  // flatMap using flatMap inside as a filter to ensure all emailAddresses items have both a name and an address
  const contactMap = contactResponse.data.value.flatMap((contact) =>
    contact.emailAddresses.flatMap((emailaddress) =>
      emailaddress.name && emailaddress.address ? [emailaddress] : []
    )
  );
  contacts = contacts.concat(contactMap);
  if (contactResponse.data['@odata.nextLink']) {
    contacts = contacts.concat(await getNextContacts(contactResponse.data['@odata.nextLink']));
  }
  return contacts;
}

export async function getContacts() {
  return await getNextContacts('me/contacts?$top=1000');
}
