import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { refreshContactsList } from './contactsSlice';
import AdminStreamAccordion from './StreamAccordion';
import { fetchStreamList, selectStreams } from './streamsSlice';

function AdminStreamList({}) {
  const dispatch = useDispatch();
  const streams = useSelector(selectStreams);
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    dispatch(fetchStreamList());
    dispatch(refreshContactsList());
  }, []);

  return (
    <div>
      {streams.streams.map((stream) => (
        <AdminStreamAccordion
          key={stream.stream_id}
          stream={stream}
          expanded={expanded === stream.stream_id}
          onChange={handleChange(stream.stream_id)}
        />
      ))}
    </div>
  );
}

export default AdminStreamList;
