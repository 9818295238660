import { MenuItem, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import ChipInput from 'material-ui-chip-input';
import React, { useEffect, useState } from 'react';
import Autosuggest from 'react-autosuggest';

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    left: 0,
    right: 0,
    zIndex: 1,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  textField: {
    width: '100%',
  },
}));

const renderChipInput = (inputProps) => {
  const { value, onChange, onChipChange, chips, ref, ...other } = inputProps;
  return (
    <ChipInput
      clearInputValueOnChange
      onUpdateInput={onChange}
      value={chips}
      inputRef={ref}
      onChange={(event) => {
        console.log(event);
      }}
      {...other}
    />
  );
};

const renderSuggestion = (suggestion, { query, isHighlighted }) => {
  const matches = match(suggestion.name, query);
  const parts = parse(suggestion.name, matches);

  return (
    <MenuItem
      selected={isHighlighted}
      component="div"
      onMouseDown={(e) => e.preventDefault()} // Prevent blur when clicking on menu item
    >
      <div>
        {parts.map((part, index) => {
          return part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
            <span key={String(index)}>{part.text}</span>
          );
        })}
      </div>
    </MenuItem>
  );
};

const renderSuggestionsContainer = (options) => {
  const { containerProps, children } = options;
  return (
    <Paper {...containerProps} square>
      {children}
    </Paper>
  );
};

const getSuggestionValue = (suggestion) => {
  return suggestion.address;
};

function ContactAutosuggest({ contacts, onChipChange, ...other }) {
  const [suggestion, setSuggestion] = useState([]);
  const [values, setValues] = useState([]);
  const [textFieldInput, setTextFieldInput] = useState('');
  const classes = useStyles();

  useEffect(() => {
    setSuggestion(contacts);
  }, []);

  useEffect(() => {
    onChipChange(values);
  }, [values]);

  const getSuggestion = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;

    return inputLength === 0
      ? []
      : contacts.filter((suggestion) => {
          const keep =
            count < 5 && suggestion.name.toLowerCase().slice(0, inputLength) === inputValue;
          if (keep) {
            count += 1;
          }
          return keep;
        });
  };

  const handleSuggestionsFetchRequested = ({ value }) => {
    setSuggestion(getSuggestion(value));
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestion([]);
  };

  const handleTextFieldInputChange = (event, { newValue }) => {
    setTextFieldInput(newValue);
  };

  const handleAddChip = (chip) => {
    if (values.indexOf(chip) < 0) {
      setValues([...values, chip]);
      setTextFieldInput('');
    }
  };

  const handleDeleteChip = (chip, index) => {
    const temp = values.slice();
    temp.splice(index, 1);
    setValues(temp);
  };

  return (
    <Autosuggest
      theme={{
        container: classes.container,
        suggestionsContainerOpen: classes.suggestionsContainerOpen,
        suggestionsList: classes.suggestionsList,
        suggestion: classes.suggestion,
      }}
      renderInputComponent={renderChipInput}
      suggestions={suggestion}
      onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
      onSuggestionsClearRequested={handleSuggestionsClearRequested}
      renderSuggestionsContainer={renderSuggestionsContainer}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      onSuggestionSelected={(e, { suggestionValue }) => {
        handleAddChip(suggestionValue);
        e.preventDefault();
      }}
      focusInputOnSuggestionClick
      inputProps={{
        chips: values,
        value: textFieldInput,
        onChange: handleTextFieldInputChange,
        onAdd: (chip) => handleAddChip(chip),
        onDelete: (chip, index) => handleDeleteChip(chip, index),
        ...other,
      }}
    />
  );
}

export default ContactAutosuggest;
