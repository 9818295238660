import { IconButton, makeStyles } from '@material-ui/core';
import { Refresh as RefreshIcon } from '@material-ui/icons';
import React from 'react';
import { useDispatch } from 'react-redux';

import { fetchStreamList } from './streamsSlice';

const useStyles = makeStyles((theme) => ({
  button: {
    marginBottom: theme.spacing(2),
    marginLeft: 'auto',
  },
}));

function RefreshButton({}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const handleRefresh = () => {
    dispatch(fetchStreamList());
  };
  return (
    <IconButton
      aria-label="refresh"
      onClick={handleRefresh}
      className={classes.button}
      size="small">
      <RefreshIcon />
    </IconButton>
  );
}

export default RefreshButton;
