import { Card, CardContent, Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  waitingCard: {
    minWidth: 300,
    maxWidth: 800,
    marginTop: theme.spacing(3),
  },
  scheduledtime: {
    marginTop: theme.spacing(2),
  },
}));

function StreamWaitingRoom({ stream }) {
  const classes = useStyles();

  let scheduled_start;

  if (stream.live || Object.keys(stream).length === 0) {
    return null;
  }

  if (stream.scheduled_start) {
    scheduled_start = (
      <div className={classes.scheduledtime}>
        <Divider variant="middle" />
        <Typography align="center" className={classes.scheduledtime}>
          The stream is scheduled to start at:
          <br />
          {format(new Date(stream.scheduled_start), 'PPPPp')}
        </Typography>
      </div>
    );
  }

  return (
    <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
      <Grid item>
        <Card className={classes.waitingCard} variant="outlined">
          <CardContent>
            <Typography align="center">This stream is not currently live</Typography>
            {/* <Typography align="center">
              This page will automatically refresh when the stream is available.
            </Typography> */}
            {scheduled_start}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default StreamWaitingRoom;
