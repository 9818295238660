import axios, { unauthAxiosInstance } from './axios';

export async function createStream(stream) {
  const newStream = await axios.post('streams', JSON.stringify(stream));
  return newStream.data;
}

export async function deleteStream(stream_id) {
  const deleteResult = await axios.delete(`streams/${stream_id}`);
  return deleteResult.data;
}

export async function updateStream(stream) {
  const updatedStream = await axios.put(`streams/${stream.stream_id}`, JSON.stringify(stream));
  return updatedStream.data;
}

export async function fetchList() {
  const response = await axios.get('streams');
  const streamList = response.data || [];
  return streamList;
}

export async function getClientStream(stream_id, play_password = '') {
  const encodedPassword = encodeURIComponent(play_password);
  const response = await unauthAxiosInstance.get(
    `streaminfo/${stream_id}?play_password=${encodedPassword}`
  );
  return response.data;
}
