import { useMsal } from '@azure/msal-react';
import { Button } from '@material-ui/core';
import React from 'react';

/**
 * Renders a button which, when selected, will open a popup for logout
 */
const SignOutButton = () => {
  const { instance } = useMsal();

  const handleLogout = (instance) => {
    instance
      .logoutPopup({
        mainWindowRedirectUri: window.location.origin,
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <Button variant="outlined" onClick={() => handleLogout(instance)}>
      Sign out
    </Button>
  );
};

export default SignOutButton;
