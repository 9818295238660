import { Box, Fade, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import config from 'react-global-configuration';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import urljoin from 'url-join';

import { useURLQuery } from '../../utils/hooks';
import { getClientStream } from './clientStreamSlice';
import StreamPasswordEntry from './PasswordPrompt';
import StreamPlayer from './StreamPlayer';
import StreamTitle from './StreamTitle';
import StreamWaitingRoom from './WaitingRoom';

function StreamView({}) {
  const { streamKey } = useParams();
  const query = useURLQuery();
  const dispatch = useDispatch();
  const clientStream = useSelector((state) => state.clientStream);

  const encodedPassword = query.get('ps');
  const presetPassword = encodedPassword ? base64url.decode(encodedPassword) : '';

  const streamSource = urljoin(config.get('playURI'), `${streamKey}.m3u8`);

  useEffect(() => {
    dispatch(getClientStream({ stream_id: streamKey, play_password: presetPassword }));
  }, []);

  return (
    <Box my={4}>
      {clientStream.error?.status_code === 403 && (
        <StreamPasswordEntry
          stream_id={streamKey}
          errorMessage={clientStream.error.message}
          loading={clientStream.loading === 'loading'}
        />
      )}
      {clientStream.stream && (
        <>
          <StreamTitle stream={clientStream.stream} />
          <StreamWaitingRoom stream={clientStream.stream}></StreamWaitingRoom>
          {(clientStream.stream.available || clientStream.stream.live) && (
            <StreamPlayer source={streamSource} available={clientStream.stream.available} />
          )}
        </>
      )}
    </Box>
  );
}

export default StreamView;
