import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getContacts } from '../../utils/GraphService';
import { enqueueSnackbar } from '../Notifications/notifierSlice';

export const refreshContactsList = createAsyncThunk(
  'contacts/refreshContactsList',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const contactList = await getContacts();
      return contactList;
    } catch (err) {
      const error = err.response?.data?.message || err.message;
      dispatch(
        enqueueSnackbar({
          message: `Error fetching Outlook contacts: ${error}`,
          options: {
            variant: 'error',
          },
        })
      );
      throw rejectWithValue(error);
    }
  }
);

export const contactsSlice = createSlice({
  name: 'contacts',
  initialState: {
    contacts: [],
    loading: 'idle',
    lastRefresh: undefined,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(refreshContactsList.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(refreshContactsList.rejected, (state, action) => {
      state.loading = 'idle';
      state.error = action.error;
    });
    builder.addCase(refreshContactsList.fulfilled, (state, action) => {
      state.loading = 'idle';
      state.contacts = action.payload;
      state.lastRefresh = Date.now();
    });
  },
});
