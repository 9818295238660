const localhost = location.hostname === 'localhost' || location.hostname === '127.0.0.1';

const backendURL = localhost ? 'http://localhost:8080' : 'https://rtmp-api.hgit.nz';
const rtmpURL = localhost ? 'rtmp://localhost:19350' : 'rtmp://rtmp.hgit.nz';
//const rtmpURL = 'rtmp://rtmp-beta.hgit.nz:11935';
//const rtmpURL = 'rtmp://localhost:19350';

const config = {
  playURI: `${backendURL}/live/`,
  apiURI: `${backendURL}/api/`,
  streamURI: `${rtmpURL}/stream/`,
  graphURI: 'https://graph.microsoft.com/v1.0/',
  default_publish_password: 'holmeslive',
};

export default config;
