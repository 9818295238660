import { createTheme } from '@material-ui/core/styles';

const Theme = createTheme({
  palette: {
    primary: {
      main: '#F89A21',
    },
  },
});

export default Theme;
