import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { useUnwrapAsyncThunk } from '../../utils/hooks';
import EditStreamForm from './EditStreamForm';
import { selectStreams, updateExistingStream } from './streamsSlice';

function EditStream({ stream }) {
  const [open, setOpen] = useState(false);
  const unwrap = useUnwrapAsyncThunk();
  const streams = useSelector(selectStreams);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (stream) => {
    try {
      await unwrap(updateExistingStream(stream));

      handleClose();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Button onClick={handleOpen}>Edit</Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Stream</DialogTitle>
        <DialogContent>
          <EditStreamForm stream={stream} onSubmit={handleSubmit} />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleClose}
            disabled={streams.loading === 'loading'}
            color="default">
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            form="streamform"
            disabled={streams.loading === 'loading'}
            color="secondary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EditStream;
