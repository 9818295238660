import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getClientStream as getStream } from '../../utils/api';

export const getClientStream = createAsyncThunk(
  'clientStream/getClientStream',
  async ({ stream_id, play_password }, { rejectWithValue, dispatch }) => {
    try {
      const clientStream = await getStream(stream_id, play_password);
      dispatch(startPolling({ stream_id: stream_id, play_password: play_password }));
      return clientStream;
    } catch (err) {
      const error = err.response?.data || err.message;
      throw rejectWithValue(error);
    }
  }
);

export const clientStreamSlice = createSlice({
  name: 'clientStream',
  initialState: {
    stream: {},
    loading: 'idle',
    error: null,
    available: false,
    polling: false,
  },
  reducers: {
    startPolling(state) {
      state.polling = true;
    },
    stopPolling(state) {
      state.polling = false;
    },
    fetchClientStream(state) {
      state.loading = 'loading';
    },
    fetchClientStreamSuccess(state, action) {
      state.stream = action.payload;
      state.loading = 'idle';
    },
    fetchClientStreamFailure(state, action) {
      state.error = action.payload;
      state.loading = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getClientStream.fulfilled, (state, action) => {
      state.stream = action.payload;
      state.loading = 'idle';
      state.error = null;
    });
    builder.addCase(getClientStream.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(getClientStream.rejected, (state, action) => {
      state.stream = {};
      state.loading = 'idle';
      state.error = action.payload;
    });
  },
});

const { actions, reducer } = clientStreamSlice;
export const {
  setPlayPassword,
  startPolling,
  stopPolling,
  fetchClientStream,
  fetchClientStreamSuccess,
  fetchClientStreamFailure,
} = actions;
export default reducer;
