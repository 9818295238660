export const msalConfig = {
  auth: {
    clientId: '42973c2f-1d30-4723-a576-2d32280aa550',
    authority: 'https://login.microsoftonline.com/d3240d57-3e67-425f-8b50-c7125b49e580',
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ['openid', 'profile', 'api://42973c2f-1d30-4723-a576-2d32280aa550/access_as_user'],
};

export const graphLoginRequest = {
  scopes: ['User.Read', 'Calendars.ReadWrite'],
};

export const graphConfig = {
  graphEndpoint: 'https://graph.microsoft.com/v1.0/me',
};
