import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import { contactsSlice } from '../components/Admin/contactsSlice';
import { streamsSlice } from '../components/Admin/streamsSlice';
import { notificationsSlice } from '../components/Notifications/notifierSlice';
import { clientStreamSlice } from '../components/Stream/clientStreamSlice';
import rootSaga from '../utils/saga';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    contacts: contactsSlice.reducer,
    streams: streamsSlice.reducer,
    notifications: notificationsSlice.reducer,
    clientStream: clientStreamSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);
