import { Typography } from '@material-ui/core';
import React from 'react';

function StreamTitle({ stream }) {
  if (stream?.title) {
    return (
      <Typography variant="h4" align="center">
        {stream.title}
      </Typography>
    );
  }
  return null;
}

export default StreamTitle;
