import './index.css';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom';
import config from 'react-global-configuration';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import { msalConfig } from './authConfig';
import configuration from './config';
import * as serviceWorker from './serviceWorker';
import Theme from './Theme';
import { store } from './utils/store';

config.set(configuration);

export const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  //<React.StrictMode>
  <Router>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <ThemeProvider theme={Theme}>
          <SnackbarProvider maxSnack={8}>
            <CssBaseline />
            <App />
          </SnackbarProvider>
        </ThemeProvider>
      </Provider>
    </MsalProvider>
  </Router>,
  //</React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
