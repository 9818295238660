import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import { isPendingAction, isRejectedAction } from '../../utils/actionMatches';
import { createStream, deleteStream, fetchList, updateStream } from '../../utils/api';
import { enqueueSnackbar } from '../Notifications/notifierSlice';

export const createNewStream = createAsyncThunk(
  'streams/createNewStream',
  async (stream, { rejectWithValue, dispatch }) => {
    try {
      const newStream = await createStream(stream);
      dispatch(
        enqueueSnackbar({
          message: 'Stream created successfully!',
          options: {
            variant: 'success',
          },
        })
      );
      return newStream;
    } catch (err) {
      const error = err.response?.data?.message || err.message;
      dispatch(
        enqueueSnackbar({
          message: `Error creating stream: ${error}`,
          options: {
            variant: 'error',
          },
        })
      );
      throw rejectWithValue(error);
    }
  }
);

export const deleteExistingStream = createAsyncThunk(
  'streams/deleteExistingStream',
  async (stream, { rejectWithValue, dispatch }) => {
    try {
      await deleteStream(stream.stream_id);
      dispatch(
        enqueueSnackbar({
          message: 'Stream deleted successfully!',
          options: {
            variant: 'success',
          },
        })
      );
      return stream.stream_id;
    } catch (err) {
      const error = err.response?.data?.message || err.message;
      dispatch(
        enqueueSnackbar({
          message: `Error deleting stream: ${error}`,
          options: {
            variant: 'error',
          },
        })
      );
      throw rejectWithValue(error);
    }
  }
);

export const updateExistingStream = createAsyncThunk(
  'streams/updateExistingStream',
  async (stream, { rejectWithValue, dispatch }) => {
    try {
      const updatedStream = await updateStream(stream);
      dispatch(
        enqueueSnackbar({
          message: 'Stream updated successfully!',
          options: {
            variant: 'success',
          },
        })
      );
      return updatedStream;
    } catch (err) {
      const error = err.response?.data?.message || err.message;
      dispatch(
        enqueueSnackbar({
          message: `Error updating stream: ${error}`,
          options: {
            variant: 'error',
          },
        })
      );
      throw rejectWithValue(error);
    }
  }
);

export const fetchStreamList = createAsyncThunk(
  'streams/fetchStreamList',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const streamList = await fetchList();
      return streamList;
    } catch (err) {
      const error = err.response?.data?.message || err.message;
      dispatch(
        enqueueSnackbar({
          message: `Error fetching stream list: ${error}`,
          options: {
            variant: 'error',
          },
        })
      );
      throw rejectWithValue(error);
    }
  }
);

export const streamsSlice = createSlice({
  name: 'streams',
  initialState: {
    streams: [],
    loading: 'idle',
    error: null,
    currentRequestId: undefined,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createNewStream.fulfilled, (state, action) => {
      state.loading = 'idle';
      state.streams.push(action.payload);
    });
    builder.addCase(fetchStreamList.fulfilled, (state, action) => {
      state.loading = 'idle';
      state.streams = action.payload;
    });
    builder.addCase(deleteExistingStream.fulfilled, (state, action) => {
      state.loading = 'idle';
      state.streams = state.streams.filter((item) => item.stream_id !== action.payload);
    });
    builder.addCase(updateExistingStream.fulfilled, (state, action) => {
      state.loading = 'idle';
      const index = state.streams.findIndex((str) => str.stream_id === action.payload.stream_id);
      const newStreamList = [...state.streams];
      newStreamList[index] = action.payload;
      state.streams = newStreamList;
    });
    builder.addMatcher(isPendingAction('streams/'), (state) => {
      state.loading = 'loading';
    });
    builder.addMatcher(isRejectedAction('streams/'), (state, action) => {
      state.loading = 'idle';
      state.error = action.error;
    });
  },
});

export const selectStreams = createSelector(
  (state) => ({
    streams: state.streams.streams,
    loading: state.streams.loading,
  }),
  (state) => state
);
