import { Button, Card, CardContent, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { getClientStream } from './clientStreamSlice';

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: theme.spacing(1),
  },
  input: {
    height: 42,
  },
  passwordHeading: {
    marginBottom: theme.spacing(0),
  },
  passwordForm: {
    marginTop: theme.spacing(4),
  },
}));

function StreamPasswordEntry({ stream_id, errorMessage, loading }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [currentPlayPassword, setCurrentPlayPassword] = useState('');
  const handleChange = (e) => {
    setCurrentPlayPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(getClientStream({ stream_id: stream_id, play_password: currentPlayPassword }));
    //setPlayPassword(currentPlayPassword);
  };

  return (
    <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
      <Grid item>
        <Card variant="outlined">
          <CardContent>
            <div className={classes.passwordHeading}>
              <Typography variant="h5" align="center">
                Password Required
              </Typography>
              <Typography variant="subtitle2" align="center">
                A password is required to view this stream
              </Typography>
            </div>
            <div className={classes.passwordForm}>
              <form onSubmit={handleSubmit}>
                <TextField
                  label="Password"
                  variant="outlined"
                  size="small"
                  type="password"
                  value={currentPlayPassword}
                  onChange={handleChange}
                  helperText={errorMessage || ' '}
                  error={!!errorMessage}
                  InputProps={{ className: classes.input }}
                  disabled={loading}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.button}
                  disabled={loading}>
                  Submit
                </Button>
              </form>
            </div>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default StreamPasswordEntry;
