import DateFnsUtils from '@date-io/date-fns';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { format } from 'date-fns';
import React from 'react';
import config from 'react-global-configuration';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

const streamFormSchema = yup.object().shape({
  streamname: yup.string().required('Stream title required'),
  streamid: yup
    .string()
    .required('Stream ID required')
    .matches(/^[a-zA-Z0-9_.-]+$/, 'Only letters, numbers, and _, ., - allowed'),
  publishpassword: yup
    .string()
    .min(5, 'Must be at least 5 characters long')
    .required('Stream password required'),
  schedstart: yup.date().nullable().default(null),
});

const useStyles = makeStyles((theme) => ({
  formElement: {
    margin: theme.spacing(1),
  },
  clearButton: {
    marginTop: '20px',
  },
  advancedSettings: {
    width: '100%',
    marginTop: '20px',
    marginBottom: '10px',
  },
}));

function EditStreamForm({ stream = null, onSubmit }) {
  const classes = useStyles();

  const defaultValues = {
    streamid: stream?.stream_id || '',
    streamname: stream?.title || '',
    publishpassword: stream?.publish_password || config.get('default_publish_password'),
    playpassword: stream?.play_password || '',
    schedstart: stream?.scheduled_start
      ? format(new Date(stream.scheduled_start), 'yyyy/MM/dd HH:mm')
      : null,
    streamactive: stream?.active || true,
  };

  const {
    handleSubmit,
    formState: { dirtyFields, errors },
    setValue,
    control,
  } = useForm({
    resolver: yupResolver(streamFormSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: defaultValues,
  });

  const onSubmitForm = (data, e) => {
    const schedStart =
      Object.prototype.toString.call(data.schedstart) === '[object String]'
        ? new Date(Date.parse(data.schedstart))
        : data.schedstart;
    //e.preventDefault();
    const newStream = {
      stream_id: data.streamid,
      title: data.streamname,
      active: data.streamactive,
      play_password: data.playpassword,
      publish_password: data.publishpassword,
      scheduled_start: schedStart,
    };
    onSubmit(newStream);
  };

  const setStreamIDSuggestion = (newStreamName) => {
    if (!dirtyFields.streamid && !stream) {
      const newStreamId = newStreamName
        .trim()
        .toLowerCase()
        .replace(/[^a-zA-Z0-9_.-]+/g, '-');
      setValue('streamid', newStreamId, { shouldValidate: true });
    }
  };

  const handleClearClick = () => {
    setValue('schedstart', null);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)} id="streamform" noValidate>
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={8}>
            <Controller
              name="streamname"
              control={control}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <TextField
                  className={classes.formElement}
                  label="Stream title"
                  fullWidth
                  value={value}
                  onBlur={onBlur}
                  onChange={(event) => {
                    onChange(event);
                    setStreamIDSuggestion(event.target.value);
                  }}
                  helperText={errors[name] ? errors[name].message : 'Shown to the end user'}
                  error={!!errors[name]}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={8}>
            <Controller
              name="playpassword"
              control={control}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <TextField
                  className={classes.formElement}
                  label="Client Password"
                  fullWidth
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  helperText={
                    errors[name] ? errors[name].message : 'Used to authenticate the client'
                  }
                  error={!!errors[name]}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={8}>
            <Controller
              name="schedstart"
              control={control}
              ampm={false}
              variant="inline"
              helperText={errors.schedstart ? errors.schedstart.message : ' '}
              error={!!errors.schedstart}
              render={({ field: { onChange, value, ref } }) => (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDateTimePicker
                    onChange={onChange}
                    className={classes.formElement}
                    value={value}
                    inputRef={ref}
                    label="Scheduled Start Time"
                    format="yyyy/MM/dd HH:mm"
                    showTodayButton
                  />
                </MuiPickersUtilsProvider>
              )}
            />
            <Button className={classes.clearButton} onClick={handleClearClick}>
              Clear
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <div className={classes.advancedSettings}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Advanced stream settings</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={8}>
                      <Controller
                        name="publishpassword"
                        control={control}
                        render={({ field: { onChange, onBlur, value, name } }) => (
                          <TextField
                            className={classes.formElement}
                            label="Stream Password"
                            required
                            fullWidth
                            value={value}
                            onBlur={onBlur}
                            onChange={onChange}
                            helperText={
                              errors[name]
                                ? errors[name].message
                                : 'Used to authenticate the streaming device'
                            }
                            error={!!errors[name]}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={8}>
                      <Controller
                        name="streamid"
                        control={control}
                        render={({ field: { onChange, onBlur, value, name } }) => (
                          <TextField
                            className={classes.formElement}
                            label="Stream ID"
                            required
                            fullWidth
                            value={value}
                            onBlur={onBlur}
                            onChange={onChange}
                            disabled={!!stream}
                            helperText={
                              errors[name] ? errors[name].message : "Used for the stream's URL"
                            }
                            error={!!errors[name]}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </div>
        </Grid>
      </Grid>
    </form>
  );
}

export default EditStreamForm;
