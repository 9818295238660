import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useUnwrapAsyncThunk } from '../../utils/hooks';
import EditStreamForm from './EditStreamForm';
import { createNewStream, selectStreams } from './streamsSlice';

const useStyles = makeStyles((theme) => ({
  button: {
    marginBottom: theme.spacing(2),
  },
}));

function NewStream({}) {
  const [open, setOpen] = useState(false);
  const unwrap = useUnwrapAsyncThunk();
  const streams = useSelector(selectStreams);
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (newStream) => {
    try {
      await unwrap(createNewStream(newStream));
      handleClose();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleOpen} className={classes.button}>
        New Stream
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Stream</DialogTitle>
        <DialogContent>
          <EditStreamForm onSubmit={handleSubmit} />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleClose}
            disabled={streams.loading === 'loading'}
            color="default">
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            form="streamform"
            disabled={streams.loading === 'loading'}
            color="secondary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default NewStream;
