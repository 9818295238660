import { useIsAuthenticated } from '@azure/msal-react';
import { Box, makeStyles } from '@material-ui/core';
import React from 'react';

import NewStream from './Admin/NewStream';
import RefreshButton from './Admin/RefreshButton';
import AdminStreamList from './Admin/StreamList';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

function AdminView({}) {
  const classes = useStyles();
  const isAuthenticated = useIsAuthenticated();

  return (
    <Box my={4}>
      {!isAuthenticated ? (
        <div></div>
      ) : (
        <div>
          <div className={classes.root}>
            <NewStream />
            <RefreshButton />
          </div>
          <AdminStreamList />
        </div>
      )}
    </Box>
  );
}

export default AdminView;
