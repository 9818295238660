import { IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { removeSnackbar } from './notifierSlice';

let displayed = [];

function Notifier({}) {
  const dispatch = useDispatch();
  const notifications = useSelector((store) => store.notifications.notifications || []);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  useEffect(() => {
    notifications.forEach(({ key, message, options = {}, dismissed = false }) => {
      if (dismissed) {
        // Dismiss snackbar
        closeSnackbar(key);
        return;
      }

      // Do nothing if snackbar is already displayed
      if (displayed.includes(key)) return;

      // Display snackbar
      enqueueSnackbar(message, {
        key,
        action: (key) => (
          <IconButton
            size="small"
            onClick={() => closeSnackbar(key)}
            aria-label="Dismiss notification">
            <CloseIcon />
          </IconButton>
        ),
        onClick: () => {
          closeSnackbar(key);
        },
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (event, myKey) => {
          // Remove from the store
          dispatch(removeSnackbar(myKey));
          removeDisplayed(myKey);
        },
        ...options,
      });
      // Keep track of displayed snackbars
      storeDisplayed(key);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
}

export default Notifier;
