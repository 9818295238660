import DateFnsUtils from '@date-io/date-fns';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import base64url from 'base64url';
import ChipInput from 'material-ui-chip-input';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import isEmail from 'validator/es/lib/isEmail';
import * as yup from 'yup';

import graphAPI from '../../utils/graphapi';
import { enqueueSnackbar as enqueueSnackbarAction } from '../Notifications/notifierSlice';
import ContactAutosuggest from './ContactAutosuggest';
import { updateExistingStream } from './streamsSlice';

const useStyles = makeStyles((theme) => ({
  formElement: {
    margin: theme.spacing(1),
    //marginLeft: theme.spacing(1),
    //marginBottom: theme.spacing(2),
  },
  chipInput: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  contactLoadingIndicator: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

const inviteFormSchema = yup.object().shape({
  attendees: yup.array().min(1, 'At least one attendee required').required('Attendee required'),
  teamsmeeting: yup.boolean(),
  subject: yup
    .string()
    .matches(/^[^\n]*$/, 'No line breaks allowed in subject')
    .required('Subject required'),
  starttime: yup.date().required('Start time required').typeError('Invalid Date'),
  finishtime: yup.date().required('Finish time required').typeError('Invalid Date'),
});

function sendInvitation(inviteData) {
  const method = 'POST';
  const requestOptions = {
    method: method,
    headers: { 'Content-Type': 'application/json' },
    data: JSON.stringify(inviteData),
  };
  graphAPI
    .request('me/events', requestOptions)
    .then((response) => {
      if (response.status === 401) {
        logout();
        return null;
      }
      return response;
    })
    .then((response) => {
      if (response && response.status === 201) {
        return response.data;
      }
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
}

function StreamInvitationForm({ stream }) {
  const dispatch = useDispatch();
  const contacts = useSelector((state) => state.contacts);
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [sending, setSending] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const inviteStartTime = new Date(stream.scheduled_start ? stream.scheduled_start : Date.now());
  const inviteFinishTime = new Date(
    new Date(inviteStartTime).setHours(inviteStartTime.getHours() + 1)
  );
  const clientLink = `${window.location.protocol}//${window.location.host}/stream/${stream.stream_id}`;
  const clientLinkWithPassword = stream.play_password
    ? `${clientLink}?ps=${base64url(stream.play_password)}`
    : clientLink;

  const defaultBody =
    `You've been invited to view a Holmes Live stream\n\nClick this link to view the stream: ${clientLink}` +
    (stream.play_password ? `\n\nWhen prompted, enter this password: ${stream.play_password}` : '');

  const defaultValues = {
    attendees: [],
    subject: `Holmes Live Stream - ${stream.title}`,
    body: defaultBody,
    teamsmeeting: true,
    starttime: inviteStartTime,
    finishtime: inviteFinishTime,
  };

  const {
    handleSubmit,
    formState: { dirtyFields, errors },
    setValue,
    control,
  } = useForm({
    resolver: yupResolver(inviteFormSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: defaultValues,
  });

  const onSubmitForm = async (data) => {
    setSending(true);
    const attendees = data.attendees.map((email) => ({
      emailAddress: {
        address: email,
      },
      type: 'required',
    }));
    const inviteData = {
      allowNewTimeProposals: false,
      responseRequested: true,
      subject: data.subject,
      start: {
        dateTime: new Date(data.starttime).toISOString(),
        timeZone: 'UTC',
      },
      end: {
        dateTime: new Date(data.finishtime).toISOString(),
        timeZone: 'UTC',
      },
      body: {
        content: (data.body + '\n\n').replace('\n', '<br />'),
        contentType: 'html',
      },
      attendees: attendees,
      isOnlineMeeting: data.teamsmeeting,
    };
    try {
      console.log(inviteData);
      await sendInvitation(inviteData);
      const { available: unusedAvailableProperty, ...streamData } = stream; // strip available property from stream to submit
      dispatch(
        updateExistingStream({
          ...streamData,
          invitation_sent: true,
        })
      );
      enqueueSnackbar({
        message: 'Invitation sent!',
        options: {
          variant: 'success',
        },
      });
      setOpen(false);
    } catch {
      enqueueSnackbar({
        message: 'Error sending invitation',
        options: {
          variant: 'error',
        },
      });
    } finally {
      setSending(false);
    }
  };

  const handleStartTimeChange = (event) => {
    if (!dirtyFields.finishtime) {
      const currentStartTime = event;
      const newFinishTime = new Date(
        new Date(currentStartTime).setHours(currentStartTime.getHours() + 1)
      );
      setValue('finishtime', newFinishTime);
    }
  };

  return (
    <div>
      <Button onClick={handleOpen}>Send invitation</Button>
      {stream.invitation_sent ? (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Invitation Already Sent</DialogTitle>
          <DialogContent>
            <Typography gutterBottom>This invitation has already been sent.</Typography>
            <Typography>
              To make further changes (eg to invite more people) open the event in your Outlook
              calendar.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Okay</Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog open={open} onClose={handleClose} TransitionProps={{ unmountOnExit: true }}>
          <DialogTitle>Send Meeting Invitation</DialogTitle>
          <DialogContent>
            <form onSubmit={handleSubmit(onSubmitForm)} id="invite-form" noValidate>
              <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={8}>
                    <Controller
                      name="attendees"
                      control={control}
                      render={({ field: { onChange, onBlur, value, name } }) => (
                        <ContactAutosuggest
                          className={classes.chipInput}
                          disabled={sending}
                          label="Attendees"
                          fullWidth
                          contacts={contacts.contacts}
                          onChipChange={onChange}
                          // onChange={onChange}
                          // onBlur={onBlur}
                          newChipKeys={['Enter']}
                          blurBehavior="add"
                          onBeforeAdd={(chip) => {
                            return isEmail(chip);
                          }}
                          helperText={errors[name] ? errors[name].message : ' '}
                          error={!!errors[name]}
                        />
                      )}
                    />
                  </Grid>
                  {contacts.loading == 'loading' && (
                    <Grid item container xs={12} sm={4} direction="row" alignItems="flex-end">
                      <Grid item className={classes.contactLoadingIndicator}>
                        <CircularProgress size={20} />
                      </Grid>
                      <Grid item className={classes.contactLoadingIndicator}>
                        <Typography display="inline" variant="caption">
                          Loading contacts
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={8}>
                    <Controller
                      name="subject"
                      control={control}
                      render={({ field: { onChange, onBlur, value, name } }) => (
                        <TextField
                          label="Subject"
                          className={classes.formElement}
                          disabled={sending}
                          fullWidth
                          multiline
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          helperText={errors[name] ? errors[name].message : ' '}
                          error={!!errors[name]}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={8}>
                    <Controller
                      name="starttime"
                      control={control}
                      render={({ field: { onChange, onBlur, value, name } }) => (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDateTimePicker
                            className={classes.formElement}
                            onChange={(event) => {
                              handleStartTimeChange(event);
                              onChange(event);
                            }}
                            disabled={sending}
                            value={value}
                            ampm={false}
                            showTodayButton
                            openTo="hours"
                            format="yyyy/MM/dd HH:mm"
                            label="Start time"
                            helperText={errors[name] ? errors[name].message : ' '}
                            error={!!errors[name]}
                          />
                        </MuiPickersUtilsProvider>
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={8}>
                    <Controller
                      name="finishtime"
                      control={control}
                      render={({ field: { onChange, onBlur, value, name } }) => (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDateTimePicker
                            className={classes.formElement}
                            onChange={onChange}
                            disabled={sending}
                            value={value}
                            ampm={false}
                            showTodayButton
                            openTo="hours"
                            format="yyyy/MM/dd HH:mm"
                            label="End time"
                            helperText={errors[name] ? errors[name].message : ' '}
                            error={!!errors[name]}
                          />
                        </MuiPickersUtilsProvider>
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={8}>
                    <Controller
                      name="body"
                      control={control}
                      rules={{ pattern: /^.$/ }}
                      render={({ field: { onChange, onBlur, value, name } }) => (
                        <TextField
                          label="Invitation Body"
                          className={classes.formElement}
                          disabled={sending}
                          fullWidth
                          multiline
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          helperText={errors[name] ? errors[name].message : ' '}
                          error={!!errors[name]}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={8}>
                    <Controller
                      name="teamsmeeting"
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <FormControlLabel
                          label="Attach Teams Meeting information"
                          control={
                            <Switch checked={value} disabled={sending} onChange={onChange} />
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <DialogActions>
            <Button type="submit" form="invite-form" disabled={sending}>
              Submit
            </Button>
            <Button onClick={handleClose} disabled={sending}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

export default StreamInvitationForm;
