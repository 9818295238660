import { useMsal } from '@azure/msal-react';
import { Box, Button } from '@material-ui/core';
import React from 'react';

import { loginRequest } from '../authConfig';

function SignInButton({}) {
  const { instance } = useMsal();

  const handleLogin = (instance) => {
    instance.loginPopup(loginRequest).catch((e) => {
      console.error(e);
    });
  };

  return (
    <Button variant="outlined" onClick={() => handleLogin(instance)}>
      Sign in
    </Button>
  );
}

export default SignInButton;
