import { Box, CircularProgress, Fade, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';

import Clappr from './Clappr';

function StreamPlayer({ source, available }) {
  return (
    <Box marginTop={3}>
      {available ? (
        <Fade in={true} mountOnEnter={true} unmountOnExit={true}>
          <Paper elevation={4}>
            <Clappr source={source} />
          </Paper>
        </Fade>
      ) : (
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
          <Grid item>
            <CircularProgress size={30} />
          </Grid>
          <Grid item>
            <Typography>Loading...</Typography>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

export default StreamPlayer;
