import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import base64url from 'base64url';
import clsx from 'clsx';
import { format } from 'date-fns';
import React, { useState } from 'react';
import config from 'react-global-configuration';
import { useDispatch } from 'react-redux';
import urljoin from 'url-join';

import { enqueueSnackbar as enqueueSnackbarAction } from '../Notifications/notifierSlice';
import DeleteStream from './DeleteStream';
import DetailRow from './DetailRow';
import EditStream from './EditStream';
import StreamInvitationForm from './InvitationForm';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 500,
    paddingTop: theme.spacing(0.2),
    marginRight: theme.spacing(2),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
    paddingTop: theme.spacing(0.2),
  },
  liveSince: {
    color: theme.palette.error.main,
  },
  buttonSplitColumn: {
    display: 'flex',
  },
  buttonSplit: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
    paddingTop: theme.spacing(1.4),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  headingButton: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      width: '150px',
    },
  },
  headingColumn: {
    alignItems: 'center',
    minWidth: 200,
    [theme.breakpoints.up('sm')]: {
      flexBasis: '30%',
    },
    [theme.breakpoints.down('sm')]: {
      flexBasis: '50%',
    },
  },
  schedTimeColumn: {
    flexBasis: '15%',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  secondaryHeadingColumn: {
    marginLeft: 'auto',
  },
  copyColumn: {
    display: 'flex',
  },
  buttonsColumn: {
    display: 'flex',
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      minWidth: '580px',
    },
    [theme.breakpoints.up('sm')]: {
      flexBasis: '55%',
    },
    [theme.breakpoints.down('sm')]: {
      flexBasis: '40%',
      flexWrap: 'wrap',
    },
    marginTop: theme.spacing(-1),
  },
  buttonDivider: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  detailsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
}));

function AdminStreamAccordion({ stream, expanded, onChange }) {
  const dispatch = useDispatch();
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));

  const classes = useStyles();
  //const [expanded, setExpanded] = useState(false);

  //const handleExpandedChange = () => {
  //  setExpanded(!expanded);
  //};

  const handleClose = () => {
    onChange(null, false);
  };

  const handleCopyButton = (event, copyText, snackbarTitle) => {
    event.stopPropagation();
    navigator.clipboard.writeText(copyText);
    enqueueSnackbar({
      message: `${snackbarTitle} copied to clipboard`,
      options: {
        variant: 'success',
      },
    });
  };

  const baseStreamURI = config.get('streamURI');

  const streamKey = `${stream.stream_id}?password=${stream.publish_password}`;

  const clientLink = `${window.location.protocol}//${window.location.host}/stream/${stream.stream_id}`;

  // const clientLinkWithPassword = stream.play_password
  //   ? `${window.location.protocol}//${window.location.host}/stream/${
  //       stream.stream_id
  //     }?ps=${base64url(stream.play_password)}`
  //   : `${window.location.protocol}//${window.location.host}/stream/${stream.stream_id}`;

  const streamingUrl = urljoin(
    baseStreamURI,
    `${stream.stream_id}?password=${stream.publish_password}`
  );

  const scheduledStartTime = stream.scheduled_start
    ? format(new Date(stream.scheduled_start), 'yyyy/MM/dd HH:mm')
    : 'No scheduled time';

  const createdTime = stream.timestamp
    ? format(new Date(stream.timestamp), 'yyyy/MM/dd HH:mm')
    : 'No created time';

  const liveStartTime = stream.live_start
    ? format(new Date(stream.live_start), 'yyyy/MM/dd HH:mm')
    : 'Stream has not started yet';

  const liveStartTimeShort = stream.live_start
    ? format(new Date(stream.live_start), 'HH:mm')
    : 'Stream has not started yet';

  const liveEndTime = stream.live_end
    ? format(new Date(stream.live_end), 'yyyy/MM/dd HH:mm')
    : 'Stream has not ended yet';

  return (
    <Accordion expanded={expanded} onChange={onChange} TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div className={classes.headingColumn}>
          <Typography className={classes.heading}>{stream.title}</Typography>
        </div>
        <div className={classes.schedTimeColumn}>
          {stream.live ? (
            <Tooltip title={liveStartTime}>
              <Typography className={clsx(classes.secondaryHeading, classes.liveSince)}>
                Live since {liveStartTimeShort}
              </Typography>
            </Tooltip>
          ) : (
            <Tooltip title="Scheduled start time">
              <Typography className={classes.secondaryHeading}>{scheduledStartTime}</Typography>
            </Tooltip>
          )}
        </div>
        <div className={classes.secondaryHeadingColumn}>
          <div style={{ display: 'flex' }}>
            <div className={classes.copyColumn}>
              <Typography className={classes.secondaryHeading} display="inline">
                Copy:
              </Typography>
            </div>
            <div className={classes.buttonsColumn} onClick={(e) => e.stopPropagation()}>
              <Tooltip title={clientLink}>
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.headingButton}
                  onClick={(event) => handleCopyButton(event, clientLink, 'Client Link')}>
                  Client Link
                </Button>
              </Tooltip>
              <Tooltip title={stream.play_password}>
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.headingButton}
                  onClick={(event) =>
                    handleCopyButton(event, stream.play_password, 'Client Password')
                  }
                  disabled={!stream.play_password}>
                  Client Password
                </Button>
              </Tooltip>
              <Divider orientation="vertical" flexItem={true} className={classes.buttonDivider} />
              <Tooltip title={streamingUrl}>
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.headingButton}
                  onClick={(event) => handleCopyButton(event, streamingUrl, 'GoPro RTMP URL')}>
                  GoPro RTMP URL
                </Button>
              </Tooltip>
              <div className={classes.buttonSplitColumn}>
                <Typography className={classes.buttonSplit} display="inline">
                  or
                </Typography>
              </div>
              <Tooltip title={streamKey}>
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.headingButton}
                  onClick={(event) => handleCopyButton(event, streamKey, 'Mevo Stream Key')}>
                  Mevo Stream Key
                </Button>
              </Tooltip>
            </div>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className={classes.detailsContainer}>
          <DetailRow title="Client Link" content={clientLink} />
          <DetailRow title="Client Password" content={stream.play_password} />
          <DetailRow title="Scheduled Time" content={scheduledStartTime} />
          <DetailRow title="Invitation Sent" content={stream.invitation_sent ? 'Yes' : 'No'} />
          <DetailRow title="Currently live" content={stream.live ? 'Yes' : 'No'} />
          <DetailRow title="Latest stream start" content={liveStartTime} />
          <DetailRow title="Latest stream end" content={liveEndTime} />
          <Divider className={classes.divider} light />
          <DetailRow title="GoPro RTMP URL" content={streamingUrl} />
          <DetailRow title="Mevo Stream URL" content={baseStreamURI} />
          <DetailRow title="Mevo Stream Key" content={streamKey} />
          <Divider className={classes.divider} light />
          <DetailRow title="Created By" content={stream.created_by_name} />
          <DetailRow title="Created At" content={createdTime} />
        </div>
      </AccordionDetails>
      <AccordionActions>
        <StreamInvitationForm stream={stream} />
        <EditStream stream={stream} />
        <DeleteStream stream={stream} />
        <Button onClick={handleClose}>Close</Button>
      </AccordionActions>
    </Accordion>
  );
}

export default AdminStreamAccordion;
