import Clappr from 'clappr';
import LevelSelector from 'level-selector';
import React from 'react';
import sizeMe from 'react-sizeme';

class ClapprPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.playerRef = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const sourceChanged = nextProps.source !== this.props.source;
    const sizeChanged = nextProps.size.width !== this.props.size.width;
    this.props = nextProps;
    this.setState(nextState);
    if (sourceChanged) {
      this.changeSource(nextProps.source);
    }
    if (sizeChanged) {
      this.changeSize(nextProps.size.width);
    }
    return false;
  }

  componentDidMount() {
    this.changeSource(this.props.source);
  }

  componentWillUnmount() {
    this.destroyPlayer();
  }

  changeSize(width) {
    if (this.player) {
      this.player.resize({
        width: width,
        height: width / (16 / 9),
      });
    }
  }

  changeSource(source) {
    if (this.player) {
      this.destroyPlayer();
    }
    this.player = new Clappr.Player({
      parent: this.playerRef.current,
      hlsMinimumDvrSize: 0,
      hlsjsConfig: {
        enableWorker: true,
      },
      plugins: [LevelSelector],
      levelSelectorConfig: {
        title: 'Quality',
        labelCallback: function (playbackLevel) {
          return playbackLevel.level.height + 'p'; // 720p
        },
      },
      width: this.props.size.width,
      height: this.props.size.width / (16 / 9),
      autoPlay: true,
      source: source,
    });
  }

  destroyPlayer() {
    if (this.player) {
      this.player.destroy();
    }
    this.player = null;
  }

  render() {
    return (
      <div>
        <div ref={this.playerRef}></div>
      </div>
    );
  }
}

export default sizeMe({ monitorWidth: true })(ClapprPlayer);
