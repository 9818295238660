import { all, call, delay, put, race, take, takeLatest } from 'redux-saga/effects';

import {
  fetchClientStreamFailure,
  fetchClientStreamSuccess,
  startPolling,
  stopPolling,
} from '../components/Stream/clientStreamSlice';
import { getClientStream } from './api';

function* fetchStream(action) {
  while (true) {
    try {
      const data = yield call(() =>
        getClientStream(action.payload.stream_id, action.payload.play_password)
      );
      yield put(fetchClientStreamSuccess(data));
      yield delay(4000);
    } catch (err) {
      const error = err.response?.data || err.message;
      yield put(fetchClientStreamFailure(error));
      yield put(stopPolling());
    }
  }
}

function* watchFetchStreamSaga() {
  while (true) {
    const action = yield take(startPolling.type);
    yield race([call(fetchStream, action), take(stopPolling.type)]);
  }
}

export default function* root() {
  yield all([watchFetchStreamSaga()]);
}
