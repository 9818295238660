import axios from 'axios';

import { graphLoginRequest } from '../authConfig';
import configuration from '../config';
import { msalInstance } from '../index';

const axiosInstance = axios.create({
  baseURL: configuration['graphURI'],
});

function fetchAccessToken() {
  const accounts = msalInstance.getAllAccounts();
  const currentAccount = accounts.length > 0 ? accounts[0] : [];
  return new Promise((resolve) => {
    msalInstance
      .acquireTokenSilent({ ...graphLoginRequest, account: currentAccount })
      .then((response) => {
        resolve(response.accessToken);
      });
  });
}

axiosInstance.interceptors.request.use(
  async (config) => {
    const accessToken = await fetchAccessToken();
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default axiosInstance;
