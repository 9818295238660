import { createSlice } from '@reduxjs/toolkit';

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    notifications: [],
  },
  reducers: {
    enqueueSnackbar(state, { payload }) {
      const key = payload.options && payload.options.key;
      const newNotification = {
        ...payload,
        key: key || new Date().getTime() + Math.random(),
      };
      state.notifications = [...state.notifications, newNotification];
    },
    closeSnackbar(state, { payload }) {
      const dismissAll = !payload;
      state.notifications = state.notifications.map((notification) =>
        dismissAll || notification.key === payload
          ? { ...notification, dismissed: true }
          : { ...notification }
      );
    },
    removeSnackbar(state, { payload }) {
      state.notifications = state.notifications.filter(
        (notification) => notification.key !== payload
      );
    },
  },
});

const { actions, reducer } = notificationsSlice;
export const { enqueueSnackbar, closeSnackbar, removeSnackbar } = actions;
export default reducer;
