import { useIsAuthenticated } from '@azure/msal-react';
import { AppBar, Button, Container, Toolbar, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import React from 'react';
import { Link, Route, Routes } from 'react-router-dom';

import AdminView from './components/AdminView';
import Notifier from './components/Notifications/Notifier';
import SignInButton from './components/SignInButton';
import SignOutButton from './components/SignOutButton';
import StreamView from './components/Stream/StreamView';

const useStyles = makeStyles((theme) => ({
  headerLink: {
    textDecoration: 'none',
  },
  button: {
    marginLeft: theme.spacing(1),
  },
  input: {
    height: 42,
  },
  videoplayer: {
    marginTop: theme.spacing(5),
  },
  logo: {
    maxWidth: 50,
    marginRight: theme.spacing(3),
    float: 'left',
  },
  authButtons: {
    marginLeft: 'auto',
    marginRight: -12,
  },
  navButton: {
    marginRight: 10,
  },
}));

function App(props) {
  const isAuthenticated = useIsAuthenticated();

  const classes = useStyles();
  const WhiteTextTypography = withStyles({
    root: {
      color: '#FFFFFF',
    },
  })(Typography);

  return (
    <>
      <Notifier />
      <AppBar position="relative">
        <Toolbar>
          <Link to={'/'} className={classes.headerLink}>
            <img src="/Holmes_White.png" alt="Holmes Logo" className={classes.logo} />
            <WhiteTextTypography variant="h6" noWrap>
              Holmes Live
            </WhiteTextTypography>
          </Link>
          {isAuthenticated ? (
            <>
              <div className={classes.authButtons}>
                <Button component={Link} to="/admin" className={classes.navButton}>
                  Admin
                </Button>
                <SignOutButton></SignOutButton>
              </div>
            </>
          ) : (
            <div className={classes.authButtons}>
              <SignInButton></SignInButton>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg" component="main">
        <Routes>
          <Route path="/" element={<StreamView classes={classes} />} />
          <Route path="/stream" element={<StreamView classes={classes} />} />
          <Route path="/stream/:streamKey" element={<StreamView />} />
          <Route path="/admin" element={<AdminView />} />
        </Routes>
      </Container>
    </>
  );
}

export default App;
