import { unwrapResult } from '@reduxjs/toolkit';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

export const useUnwrapAsyncThunk = () => {
  const dispatch = useDispatch();
  return useCallback(
    (asyncThunk) => {
      return dispatch(asyncThunk).then(unwrapResult);
    },
    [dispatch]
  );
};

export const useURLQuery = () => {
  return new URLSearchParams(useLocation().search);
};
