import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { useUnwrapAsyncThunk } from '../../utils/hooks';
import { deleteExistingStream, selectStreams } from './streamsSlice';

function DeleteStream({ stream }) {
  const [open, setOpen] = useState(false);
  const unwrap = useUnwrapAsyncThunk();
  const streams = useSelector(selectStreams);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    try {
      await unwrap(deleteExistingStream(stream));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Button onClick={handleOpen}>Delete</Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="confirm-dialog">
        <DialogTitle id="confirm-dialog">Delete stream</DialogTitle>
        <DialogContent>Are you sure you want to delete the stream "{stream.title}"?</DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleClose}
            disabled={streams.loading === 'loading'}
            color="secondary">
            No
          </Button>
          <Button
            variant="contained"
            onClick={handleDelete}
            disabled={streams.loading === 'loading'}
            color="default">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DeleteStream;
