import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  detailRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(1),
  },
  detailHeading: {
    flexBasis: '20%',
    minWidth: 150,
  },
  detailContent: {
    flexBasis: '80%',
  },
  detailHeadingText: {
    fontWeight: 400,
  },
  detailContentText: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

function DetailRow({ title, content }) {
  const classes = useStyles();

  return (
    <div className={classes.detailRow}>
      <div className={classes.detailHeading}>
        <Typography className={classes.detailHeadingText}>{title}</Typography>
      </div>
      <div className={classes.detailContent}>
        <Typography className={classes.detailContentText}>{content}</Typography>
      </div>
    </div>
  );
}

export default DetailRow;
